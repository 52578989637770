.setWidth {
    width: 18%;
}

.iconStyle {
    background-color: #dff1fa;  
    /* padding: 1px 10px 1px 10px; */
    width: 28px;
    padding: 4px;
    border-radius: 4px;
    height: 27px;
    color: #26a3db;
}



label {
    font-size: 12px;

}

.dropdown-toggle {
    background-color: transparent;
    /* Remove background color */
    border: none;
    /* Remove border */
    box-shadow: none;
    /* Remove box shadow */
    color: inherit;
    /* Inherit text color */
    padding: 0;
    /* Remove padding */
}

.dropdown-toggle::after {
    display: none;
}

.dropdown-menu {
    font-size: 0.875rem !important;
    width: auto !important;
    /* Set the desired width */
    --bs-dropdown-min-width: 5rem !important;
    inset: 0px 0px auto auto !important;
    /* box-shadow: 0px 0px 15px 1px #ccc !important;
    border: none !important; */

}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: #f3f8fa !important
}

.dropdown-item.active,
.dropdown-item:active {
    color: #1d1b1b !important;
    background-color: transparent !important;

}

.container-radio {
    display: block;
    position: relative;
    padding-left: 23px;
    /* margin-bottom: 12px; */
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.container-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container-radio:hover input~.checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-radio input:checked~.checkmark {
    background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.container-radio .checkmark:after {
    top: 5px;
    left: 5px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: white;
}