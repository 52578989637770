.agent-column{
    
        /* border: 1px solid #ECECEC; */
        padding: 24px 30px;
        background-color: white;
        border-radius: 6px;
}
.afiliate-value{
    color: #98D12C;
    font-size: 40.22px;
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 8px;

}
.agent-heading-font{
    font-size: 1rem !important;
}
.agent-column .value{
    margin-top: 8px;
}
.download-marketing{
  cursor: pointer;
}
.download-marketing:hover{
    /* box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 20%); */
    background-color: #26A3DB;
    color: #ffff;
    border-radius: 0.375rem;
}