.custom-card {
    background-color: #e5e5e5 !important;
}

.input-field-class {
    width: 85%;
    background: none;
    font-weight: 600;
}

.label-width {
    width: 15%;
    margin-bottom: 0px;
}

.p1-20 {
    padding: 12px 20px 0px 12px;
}

.p2-20 {
    padding: 12px 20px 12px 12px;
}

.custom-button {
    background-color: #e5e5e5 !important;
    width: 20%;
    margin-left: 10px;
}

.findmyway {
    width: 75%;
}

.icon-size {
    width: 18px !important;
    height: 18px !important;
}

.icon-size1 {
    width: 25px !important;
    height: 25px !important;
}

.icon-size2 {
    width: 20px !important;
    height: 20px !important;
}

.align-end {
    justify-content: space-between;
}

.serach-box {
    background-color: #e5e5e5 !important;
}

.border-div {
    padding: 10px;
    border-bottom: 1px solid #00000052;
}


/* //Tab styles */

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #26A3DB !important;
    border-bottom: 3px solid #26A3DB !important;
    font-weight: 500;
    /* background-color: #1a91d3 !important; */
    /* border-color: var(--bs-nav-tabs-link-active-border-color); */
    /* border-radius: 20px !important; */
    /* margin-bottom: 12px !important; */
}

.nav-tabs .nav-link {
    /* background: transparent !important; */
    color: black !important;
    border: none !important;
    background: initial;
    /* border-radius: 20px !important; */
}

.nav-tabs .nav-item {
    /* margin-right: 8px; */
    /* font-size: 13px; */
    cursor: pointer;
}

.header {
    font-size: 18px;
}

.close-icon {
    height: 25px;
    width: 25px;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: #e5e5e5;
    border-radius: 50px;
}

.slide-content {
    /* position: absolute;
    top:25%;
    left: 6%;
    text-align: center; */
    min-height: 300px;
}

@media (max-width: 1000px) {
    .slide-content {
        /* position: absolute; */
        top: 50%;
        left: 40%;
        /* transform: translate(-50%, -50%); */
        text-align: center;
    }
}

@media (max-width: 800px) {
    .slide-content {
        /* position: absolute; */
        top: 50%;
        left: 45%;
        /* transform: translate(-50%, -50%); */
        text-align: center;
    }
}

.slide-content h2 {
    /* color: #ffffff; */
    /* font-size: 40px; */
    font-weight: 600;
}

.slide-content p {
    /* color: #ffffff; */
    /* margin-bottom: 7px !important; */
    /* font-size: 18px; */
}

.slider {
    position: relative;
}

.imageStyle-corosel {
    max-width: 100%;
    height: 299px;
    width: 450px !important;
    /* rotate: -13.52deg; */
    /* object-fit: contain; */
}


.right {
    width: 26px;
    height: 26px;
    position: absolute;
    bottom: 0px;
    right: 3px;
    /* transform: translateY(-50%); */
    color: #6a6d73;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 0.875rem;
    z-index: 100;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
    min-height: 300px !important;
    object-fit: contain;
    border: 1px solid transparent;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide .floatLeft {
    text-align: start !important;
}

.left {
    width: 26px;
    height: 26px;
    right: 24px;
    bottom: 0;
    display: flex;
    position: absolute;
    color: #6a6d73;
    z-index: 100;
    /* background: white; */
    justify-content: center;
    align-items: center;
    /* border: 1px solid #E5E5E5; */
    font-size: 0.875rem;
    cursor: pointer;

}

.grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
}

.column {
    /* border: 1px solid #ECECEC; */
    padding: 12px 30px;
    background-color: #dff1fa;
    border-radius: 6px;
}

.total {
    color: black;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
}

.value {
    color: #26a3db;
    font-size: 40.22px;
    font-weight: 600;
    margin-bottom: 0px;
    text-align: end;
}

.floatLeft {
    /* float: left; */
    /* margin-left: 100px; */
    /* white-space: nowrap; */
    /* display: grid; */
    /* padding: 53px 0px 0px 83px; */

    float: left;
    justify-content: center;
    flex-direction: column;
    display: flex;
    /* left: 50px; */
    padding-left: 80px;
    position: relative;
    height: 300px;
    overflow: auto;
    max-height: auto;
}

@media (max-width: 1000px) {
    .floatLeft {
        padding: 25px;
    }
}

@media (max-width: 575px) {
    .floatLeft {
        height: 500px !important;
        padding: 15px;

    }
}



.float-left {
    float: left;
}

.f-600 {
    font-weight: 600;
}

.btn-warning-clr {
    color: white;
    background-color: #FA9C75;
    border: none;
    font-weight: 500;
}

.btn-warning-clr:hover {
    color: white;
    background-color: #e98c65;
    border: none;
}

.btn-warning-clr:active {
    color: white;
    background-color: #e98c65;
    border: none;
}

.carousel-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #bbb;
    border-radius: 50%;
    margin: 0 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.carousel-dot.selected {
    background-color: #333;
}

.custom-button-new {
    background-color: #f90d29 !important;
    border-color: #f90d29 !important;
    color: #fff !important;
}

.floor-card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.floors {
    position: absolute;
    right: 5px;
    top: 70px;
    border-radius: 5px;
    width: 30px;
    height: 100px;
    background-color: #e5e5e5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
}

.floor-number {
    color: #212530;
    text-decoration: none;
    border-bottom: 1px solid #00000033;
    width: 30px;
    text-align: center;
}

.floor-number:active {
    color: #1a91d3;
}

.floor-number:last-child {
    border-bottom: none;
}

.image-card-height {
    height: 68vh;
}

.Report-button {
    background-color: #e8e8e8;
    border-color: #e8e8e8;
    color: #1a91d3;
}

.light-heading {
    color: #4950576e;
}

.text-color {
    color: #1a91d3;
}

.card {
    border: none !important;
}

.fill-image {
    height: -webkit-fill-available !important;
    position: absolute !important;
}

.content-card {
    padding: 0px !important;
}

.btn-warning-color {
    background: #efaa3a;
}

.bg-color {
    background-color: #f7f7f7 !important;
}

.alignCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .vertical-line {
    width: 19px;
    height: 4px;
    top: calc(50% - 62px/2 + 13.5px);
    transform: rotate(-90deg);
    color: #000;
} */

.selector {
    /* position: relative; */
    width: 50%;
    background-color: var(--smoke-white);
    height: 36px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* border-radius: 100px; */
    /* box-shadow: 0 0 16px rgb(0 0 0 / 20%); */
    float: right;
    /* border: 1px solid #922c88; */
    background: #dee2e69c;
    border-radius: 6px;

}

.selecotr-item {
    /* position: relative; */
    flex-basis: calc(70% / 1);
    height: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selector-item_radio {
    appearance: none;
    display: none;
}

.selector-item_label {

    position: relative;
    height: 80%;
    width: 95%;
    text-align: center;
    /* border-radius: 9999px; */
    /* line-height: 368%; */
    font-weight: 600;
    /* transition-duration: .5s; */
    /* transition-property: color, box-shadow, -webkit-transform; */
    /* transition-property: transform, color, box-shadow; */
    /* transition-property: transform, color, box-shadow, -webkit-transform; */
    /* -webkit-transform: none; 
transform: none;  */
    padding-top: 3px;
    margin-bottom: 0px;
    /* margin-top: 3px; */

}

.selector-item_radio:checked+.selector-item_label {
    background-color: #1a91d3;
    color: white;

    border-radius: 5px;
}

.location-value {
    font-weight: 600;
}

a {
    text-decoration: none !important;
}

.btn-custom {
    /* background-color: #FA9C75; */
    /* color: white; */
    font-size: 1rem;
    /* border: none; */
    padding: 7px 17px;
}

.btn-custom:hover {
    opacity: 0.8;
    /* background-color: #e5855d; */
    /* color: white !important; */
    /* border: none; */
}

.btn-custom:focus {
    opacity: 0.8;

    /* background-color: #e5855d !important; */
    /* color: white !important; */
    /* border: none; */
}

.btn-custom:active {
    opacity: 0.8;

    /* background-color: #e5855d !important; */
    /* color: white !important; */
    /* border: none; */
}

.image-cont {
    position: relative;
    /* top: 7%; */
}

.afl-code {
    font-size: 0.875rem;
    background-color: #e8e8e878;
    padding: 10px;
    border-radius: 6px;
}

.carousel .control-dots .dot {
    box-shadow: none;
    display: none;
}

.stripe-warning {
    border: 1px solid #ece5bf;
    padding: 27px;
    border-radius: 6px;
    background-color: #f8f3d6;
    color: #96722f;
}

.btn-connect {
    background-color: #f8f3d6;
    color: #96722f;
    border: 1px solid #967235;
    font-size: 0.875rem;
    /* padding: 5px 25px !important; */
    padding: 7px 17px !important;
    font-weight: 500;
}

.btn-connect:hover {
    background-color: #967235;
    color: #ffff;
    border: 1px solid transparent;
    font-size: 0.875rem;
    /* padding: 5px 25px !important; */
    padding: 7px 17px !important;
    font-weight: 500;
}

.btn-connect:active {
    background-color: #967235 !important;
    color: #ffff !important;
    border: 1px solid transparent !important;
    font-size: 0.875rem !important;
    /* padding: 5px 25px !important; */
    padding: 7px 17px !important;
    font-weight: 500 !important;
}

.select-file {
    margin-bottom: 0px;
    width: 100%;
    height: 40px;
    border: 1px dashed #26a3db;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #26a3db;
    background-color: #F0F8FC;
    cursor: pointer;
    font-size: 0.875rem;
}

.file-contain {
    margin-bottom: 0px;
    width: 100%;
    height: 40px;
    border: 1px solid #F5F6F7;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0px 3px;
    color: #26a3db;
    cursor: pointer;
    font-size: 0.875rem;
}
.file-contain:hover {
    border: 1px solid transparent;
    color: white;
    background-color: #26A3DB;
}

.file-detail {
    background-color: #f5f6f7;
    font-size: 12px;
    width: auto;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    border-radius: 5px;
    color: #6a6d73;
}