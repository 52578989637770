.react-tagsinput {
  border-radius: 6px;
  outline: initial !important;
  box-shadow: none !important;
  font-size: .8rem;
  border: 1px solid #d7d7d7;
  background: #fff;
  color: #3a3a3a;
  height: auto;
  padding: 0.5rem 0.75rem;
  line-height: 1;
}

.react-tagsinput-tag {
  background-color: #F0F8FC;
  border-radius: 20px;
  border: none;
  color: #26A3DB;
  display: inline-block;
  font-family: sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 0px;
  margin-right: 5px;
  padding: 2px 5px;
}

.react-tagsinput--focused {
  border-color: #26a3db !important;
}

.react-tagsinput-input {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 1px;
  width: 100%;
}