.align-center {
    display: flex;
    align-items: center;
}

/* .vertical-line {
    width: 19px;
    height: 4px;
    top: calc(50% - 62px/2 + 13.5px);
    transform: rotate(-90deg);
    color: black;
} */
.image-preview2 {
    width: 148px;
    height: 148px;
    display: flex;
    justify-content: center;
    border: 1px solid #ccc;
    align-items: center;
    border-radius: 20px;
    position: absolute;
}

.image_ {
    position: relative;
    min-width: 148px;
    min-height: 148px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.58);
    mask-image: linear-gradient(transparent 80%, black 70%);
}

.image-preview2 img.preview-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 20px;
}

.column1 {
    padding: 30px;
    /* Add some padding to the columns (you can adjust the value as needed) */
    border-right: 1px solid #e9e9e9;
    /* Add a vertical border on the right side of the first column */
    /* min-height: 60vh; */
}
@media (max-width: 576px) {
    .column1 {
        border-right: none !important;
    }
  }


hr {
    border-top: 1px solid #e9e9e9;
    opacity: 1;
}

.affiliate-code{
    padding: 4px 10px;
    font-size: 11.12px;
    border-radius: 6px;
    color: #26a3db;
    font-weight: 500;
    background-color: #dff1fa;
}
.afCode{
    
    color: white;
    /* text-decoration: underline; */
    cursor: pointer; 
    background-color: #26a3db;   
    font-size: 1rem !important;
    border: 1px solid transparent !important;

    /* padding: 0px 7px; */
}
.afCode:hover{
 
    color: white !important;
    /* text-decoration: underline; */
    cursor: pointer; 
    background-color: #1979a5 !important;   
    font-size: 1rem !important;
    /* padding: 0px 7px !important; */
    border: 1px solid transparent !important;
}
.afCode:active{
   
    color: white !important;
    /* text-decoration: underline; */
    cursor: pointer; 
    background-color: #1979a5 !important;   
    font-size: 1rem !important;
    /* padding: 0px 7px !important; */
    border: 1px solid transparent !important;

}
.afCode.form-control{
    color: white !important;
    /* background-color: #26a3db !important; */
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem; 
    padding: 8px 0.75rem;
    font-weight: 500;  
}
.aflCode.form-control{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

}