.image-upload {
    width: 100%;
    height: 125px;
    background-color: #F8FCFE;
    border: 2px dotted #26A3DB;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.dragAndDrop {
    font-size: 12px;
    color: #26A3DB;
    font-weight: 500;
}

.format {
    font-size: 12px;
    color: #26A3DB;
}

.preview-card {
    background-color: #F5F6F7;
}

.image-preview {
    border: 1px solid #ccc;
    height: 125px;
    width: 300px;
    border-radius: 6px;
}

.align {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.align-end {
    display: flex;
    justify-content: end;
    align-items: center;
}

.removeimage {
    font-size: 12px;
    background-color: #E5E5E5;
    padding: 3px;
    border-radius: 50%;
    cursor: pointer;
}

.btn-canvas {
    background: #E3F3FA;
    color: #26A3DB;
    padding: 8px 8px;
    border-radius: 10px;
    font-size: 17px;
}

.btn-canvas:hover {
    background: #E3F3FA;
    color: #26A3DB;
}

.crop-size {
    max-width: 45%;
}

.content-center {
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: #26A3DB; */
    font-weight: 400;
    /* margin-left: 6px; */
    font-size: 1rem;
}

.notification-editmenu {
    font-size: 0.875rem !important;
    /* width: auto !important; */
    inset: 0px -33px auto auto !important;
    box-shadow: none !important;
    /* border: none !important; */
    position: relative;
}

.dropdown-toggle-notification {
    position: absolute !important;
}

.notif-img {
    border-radius: 6px;
    width: 37px;
    height: 34px;
    position: absolute;
}

.notif-img-cont {
    border-radius: 6px;
    border: 1px solid #ccc;
    width: 37px;
    height: 35px;
    position: relative;
}
.menus-div{
    display: flex;
    justify-content: end;
}